<template>
  <b-card
    no-body
    class="mb-0 mt-1"
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>
        </b-col>

        <!-- Add Item -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Search..."
            />
            <b-button
              v-if="$can('store', 'Users')"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="$bvModal.show('modal-create-user')"
              class="text-nowrap"
            >
              Aggiungi incaricato o cliente
            </b-button>

            <user-list-create
              v-if="$can('store', 'Users')"
              :type-options="typeOptions"
              :status-options="statusOptions"
              :gender-options="genderOptions"
              :si-no-options="siNoOptions"
              @refetch-data="refetchData"
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refIscrittiListTable"
      class="position-relative mh-200"
      :items="fetchIscritti"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Nessun iscritto trovato"
      :sort-desc.sync="isSortDirDesc"
    >
      <!-- Column: Details -->
      <template #row-details="row">
        <b-card>
          <template v-if="row.item.personal_data.incaricato">
            <b-row class="mb-2">

              <b-col
                md="2"
              >
                <b-row>
                  <b-col md="12">
                    <div class="d-flex">
                      <div class="ml-0">
                        <span class="d-block">Telefono</span>
                        <span class="text-muted">+{{ row.item.personal_data.prefix }} {{ row.item.personal_data.phone_number }}</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>

              <b-col
                md="2"
              >
                <b-row>
                  <b-col md="12">
                    <div class="d-flex">
                      <div class="ml-0">
                        <span class="d-block">Data di nascita</span>
                        <span class="text-muted">{{ row.item.personal_data.incaricato.birthday }}</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                md="2"
              >
                <b-row>
                  <b-col md="12">
                    <div class="d-flex">
                      <div class="ml-0">
                        <span class="d-block">Luogo di nascita</span>
                        <span class="text-muted">{{ row.item.personal_data.incaricato.birthplace }}  {{ row.item.personal_data.incaricato.province }} {{ row.item.personal_data.incaricato.nation_of_birth }}</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                md="1"
              >
                <b-row>
                  <b-col md="12">
                    <div class="d-flex">
                      <div class="ml-0">
                        <span class="d-block">Genere</span>
                        <span class="text-muted">{{ row.item.personal_data.incaricato.gender }}</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                md="1"
              >
                <b-row>
                  <b-col md="12">
                    <div class="d-flex">
                      <div class="ml-0">
                        <span class="d-block">Sposata/o</span>
                        <span class="text-muted">{{ row.item.personal_data.incaricato.spouse ? 'Si' : 'No' }}</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                md="1"
              >
                <b-row>
                  <b-col md="6">
                    <div class="d-flex">
                      <div class="ml-0">
                        <span class="d-block">Inps</span>
                        <span class="text-muted">{{ row.item.personal_data.incaricato.inps ? 'Si' : 'No' }}</span>
                      </div>
                    </div>
                  </b-col>
                  <b-col md="6">
                    <div class="d-flex">
                      <div class="ml-0">
                        <span class="d-block">Pensionata/o</span>
                        <span class="text-muted">{{ row.item.personal_data.incaricato.retired ? 'Si' : 'No' }}</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col
                md="2"
              >
                <b-row>
                  <b-col md="12">
                    <div class="d-flex">
                      <div class="ml-0">
                        <span class="d-block">Codice Fiscale</span>
                        <span class="text-muted">{{ row.item.personal_data.incaricato.fiscal_code }}</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                md="2"
              >
                <b-row>
                  <b-col md="12">
                    <div class="d-flex">
                      <div class="ml-0">
                        <span class="d-block">Tipo di documento</span>
                        <span class="text-muted">{{ row.item.personal_data.incaricato.identity_card }}</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                md="2"
              >
                <b-row>
                  <b-col md="12">
                    <div class="d-flex">
                      <div class="ml-0">
                        <span class="d-block">Rilasciato da</span>
                        <span class="text-muted">{{ row.item.personal_data.incaricato.public_authority }}</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                md="1"
              >
                <b-row>
                  <b-col md="12">
                    <div class="d-flex">
                      <div class="ml-0">
                        <span class="d-block">Rilasciato il</span>
                        <span class="text-muted">{{ row.item.personal_data.incaricato.release_date }}</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                md="1"
              >
                <b-row>
                  <b-col md="12">
                    <div class="d-flex">
                      <div class="ml-0">
                        <span class="d-block">Scadenza il</span>
                        <span class="text-muted">{{ row.item.personal_data.incaricato.expiration_date }}</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                md="2"
              >
                <b-row>
                  <b-col md="12">
                    <div class="d-flex">
                      <div class="ml-0">
                        <span class="d-block">Iban</span>
                        <span class="text-muted">{{ row.item.personal_data.incaricato.iban }}</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col
                md="1"
              >
                <b-row>
                  <b-col md="12">
                    <div class="d-flex">
                      <div class="ml-0">
                        <span class="d-block">Qualifica</span>
                        <span class="text-muted">{{ row.item.personal_data.incaricato.qualifica.qualifica_name }}</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <b-row class="mb-2">

              <b-col
                md="2"
              >
                <b-row>
                  <b-col md="12">
                    <div class="d-flex">
                      <div class="ml-0">
                        <span class="d-block">Tipo</span>
                        <span class="text-muted">{{ row.item.company === 1 ? 'Azienda' : 'Privato' }}</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>

              <b-col
                md="2"
              >
                <b-row>
                  <b-col md="12">
                    <div class="d-flex">
                      <div class="ml-0">
                        <span class="d-block">Telefono</span>
                        <span class="text-muted">+{{ row.item.personal_data.prefix }} {{ row.item.personal_data.phone_number }}</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>

              <b-col
                md="2"
              >
                <b-row>
                  <b-col md="12">
                    <div class="d-flex">
                      <div class="ml-0">
                        <span class="d-block">Nome Azienda</span>
                        <span class="text-muted">{{ row.item.personal_data.cliente.business_name }}</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>

              <b-col
                md="2"
              >
                <b-row>
                  <b-col md="12">
                    <div class="d-flex">
                      <div class="ml-0">
                        <span class="d-block">Partita IVA</span>
                        <span class="text-muted">{{ row.item.personal_data.cliente.vat }}</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>

          <b-button
            size="sm"
            variant="outline-secondary"
            @click="row.toggleDetails"
          >
            Chiudi
          </b-button>
        </b-card>
      </template>

      <!-- Column: Code -->

      <template #cell(codice)="data">
        <div class="text-nowrap">
          <b-link @click="$bvModal.show('modal-edit-user-'+data.item.id)">
            <span class="align-text-top text-capitalize">{{ data.item.code }}</span>
          </b-link>
        </div>
      </template>

      <template #cell(stato)="data">
        <div class="text-nowrap">
          <b-badge
            :variant="data.item.active === 1 ? 'success' : 'warning'"
            class="badge"
          >
            {{ data.item.active === 1 ? 'attivo' : 'inattivo' }}
          </b-badge>
          <span class="align-text-top text-capitalize" />
        </div>
      </template>

      <template #cell(nome)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.fullName }}</span>
        </div>
      </template>

      <template #cell(cognome)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.personal_data.last_name }}</span>
        </div>
      </template>

      <template #cell(ruolo)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.type }}</span>
        </div>
      </template>

      <template #cell(primo_login)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.first_login }}</span>
        </div>
      </template>

      <template #cell(ultimo_login)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.last_login }}</span>
        </div>
      </template>

      <template #cell(abilitato)="data">
        <div class="text-nowrap text-center">
          <b-avatar
            :variant="data.item.disabled ? 'light-danger' : 'light-success'"
            rounded
          >
            <feather-icon
              :icon="data.item.disabled ? 'XIcon' : 'CheckIcon'"
              size="18"
            />
          </b-avatar>
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(azioni)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item @click="data.toggleDetails">
            <feather-icon icon="EyeIcon" />
            <span
              class="align-middle ml-50"
            >Dettagli</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('update', 'Users')"
            @click="$bvModal.show('modal-edit-user-'+data.item.id)"
          >
            <feather-icon icon="EditIcon" />
            <span
              class="align-middle ml-50"
            >Modifica</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('update', 'Users')"
            @click="$bvModal.show('modal-edit-address-'+data.item.id)"
          >
            <feather-icon icon="InboxIcon" />
            <span
              class="align-middle ml-50"
            >Indirizzi</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('destroy', 'Users') && !data.item.disabled"
            ref="swalConfirmDeleteIscritto"
            @click="swalConfirmDeleteIscritto(data.item.id)"
          >
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Cancella</span>
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('destroy', 'Users') && !!data.item.disabled"
            ref="swalConfirmDeleteIscritto"
            @click="swalConfirmRestoreIscritto(data.item.id)"
          >
            <feather-icon icon="RefreshCcwIcon" />
            <span class="align-middle ml-50">Ripristina</span>
          </b-dropdown-item>

          <user-list-edit
            v-if="$can('update', 'Users')"
            :id="'modal-edit-user-'+data.item.id"
            :user-edit-data="data.item"
            :type-options="typeOptions"
            :status-options="statusOptions"
            :gender-options="genderOptions"
            :si-no-options="siNoOptions"
            @refetch-data="refetchData"
          />

          <user-list-addresses-edit
            v-if="$can('update', 'Users')"
            :id="'modal-edit-address-'+data.item.id"
            :user-edit-address-data="data.item"
            @refetch-data="refetchData"
          />

        </b-dropdown>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalUsers"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormInput,
  BButton,
  BLink,
  BBadge,
  BAvatar,

  // BLink,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import useUserList from '@/views/iscritti/iscritti/useUserList'
import UserListEdit from '@/views/iscritti/iscritti/iscritti-edit/UserListEdit.vue'
import UserListAddressesEdit from '@/views/iscritti/iscritti/iscritti-edit/UserListAddressesEdit.vue'
import UserListCreate from '@/views/iscritti/iscritti/iscritti-create/UserListCreate.vue'

export default {
  name: 'UsersTable',
  components: {
    UserListCreate,
    UserListAddressesEdit,
    UserListEdit,
    // NewItem,
    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormInput,
    BButton,
    BLink,
    BBadge,
    vSelect,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
  },
  watch: {
  },
  mounted() {
  },
  setup() {
    const {
      // Options
      typeOptions,
      statusOptions,
      genderOptions,
      siNoOptions,
      //
      fetchIscritti,
      refetchIscritto,
      getListIscritti,
      getDraftIscritto,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refIscrittiListTable,
      refetchData,

      // Extra Filters
      //
    } = useUserList()

    return {
      // Options
      typeOptions,
      statusOptions,
      genderOptions,
      siNoOptions,
      //
      fetchIscritti,
      refetchIscritto,
      getListIscritti,
      getDraftIscritto,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refIscrittiListTable,
      refetchData,

      // Extra Filters
      //
    }
  },
  methods: {
    swalConfirmDeleteIscritto(id) {
      this.$swal({
        title: 'Sei sicura/o?',
        text: 'Non potrai annullare questa operazione',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si cancella!',
        cancelButtonText: 'Chiudi',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            store.dispatch('users/forceDeleteIscritto', id)
              .then(() => {
                this.refetchData()
                this.$swal({
                  icon: 'success',
                  title: 'Cancellato!',
                  text: 'Utente cancellato',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Cancellazione annullata',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          return result.value
        })
    },
    swalConfirmRestoreIscritto(id) {
      this.$swal({
        title: 'Sei sicura/o?',
        text: 'Non potrai annullare questa operazione',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ripristina!',
        cancelButtonText: 'Chiudi',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            store.dispatch('users/forceDeleteIscritto', id)
              .then(() => {
                this.refetchData()
                this.$swal({
                  icon: 'success',
                  title: 'Ripristinato!',
                  text: 'Utente ripristinato',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Ripristino annullato',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          return result.value
        })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped/>
