<template>
  <!-- modal -->
  <b-modal
    :id="'modal-edit-address-'+userEditAddressData.id"
    :ref="'modal-edit-address-'+userEditAddressData.id"
    :title="'Indirizzi ' + userEditAddressData.type + ' ' + userEditAddressData.fullName"
    ok-title="Salva"
    cancel-title="Chiudi"
    cancel-variant="outline-secondary"
    centered
    size="lg"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <validation-observer ref="editUserForm">
      <form @submit.stop.prevent="validationForm">

        <b-row
          v-for="(indirizzo, index) in userAddressData.addresses"
          :key="indirizzo.id"
        >

          <b-col
            lg="4"
            md="6"
            sm="12"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="number"
              rules="required"
            >

              <b-form-group
                label="Via"
                label-for="street-input"
                invalid-feedback="Obbligatorio"
              >

                <b-form-input
                  id="street-input"
                  v-model="userAddressData.addresses[index].street"
                  :state="getValidationState(validationContext)"
                  required
                />
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            lg="2"
            md="6"
            sm="12"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="number"
              rules="required"
            >

              <b-form-group
                label="Numero"
                label-for="number-input"
                invalid-feedback="Obbligatorio"
              >

                <b-form-input
                  id="number-input"
                  v-model="userAddressData.addresses[index].number"
                  :state="getValidationState(validationContext)"
                  required
                />
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            lg="4"
            md="6"
            sm="12"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="city"
              rules="required"
            >

              <b-form-group
                label="Città"
                label-for="city-input"
                invalid-feedback="Obbligatorio"
              >

                <b-form-input
                  id="city-input"
                  v-model="userAddressData.addresses[index].city"
                  :state="getValidationState(validationContext)"
                  required
                />
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            lg="2"
            md="6"
            sm="12"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="province"
              rules="required|alpha"
            >

              <b-form-group
                label="Provincia"
                label-for="province-input"
                invalid-feedback="Obbligatorio"
              >

                <b-form-input
                  id="province-input"
                  v-model="userAddressData.addresses[index].province"
                  :state="getValidationState(validationContext)"
                  required
                />
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            lg="4"
            md="6"
            sm="12"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="zip"
              rules="required"
            >

              <b-form-group
                label="CAP"
                label-for="zip-input"
                invalid-feedback="Obbligatorio"
              >

                <b-form-input
                  id="zip-input"
                  v-model="userAddressData.addresses[index].zip"
                  :state="getValidationState(validationContext)"
                  required
                />
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

      </form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BFormInput, BModal, VBModal, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required, alphaNum, integer, digits,
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    userEditAddressData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      nameState: null,
      last_nameState: null,
      emailState: null,
      roleState: null,
      statusState: null,
      passwordState: null,
      submittedNames: [],

      required,
      alphaNum,
      integer,
      digits,
    }
  },
  methods: {
    validationForm() {
      this.$refs.editUserForm.validate().then(success => {
        if (success) {
          this.handleSubmit()
        }
      })
    },
    resetModal() {
      this.resetuserData()
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.validationForm()
    },
    handleSubmit() {
      this.onSubmit(this.userEditAddressData.id)

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs[`modal-edit-address-${this.userEditAddressData.id}`].toggle('#toggle-btn')
      })
    },
  },
  setup(props, { emit }) {
    const blankUserData = {
      id: props.userEditAddressData.id,
      addresses: props.userEditAddressData.personal_data.indirizzi,
    }

    const userAddressData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userAddressData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('users/updateIscritto', userAddressData.value)
        .then(() => {
          emit('refetch-data')
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userAddressData,
      onSubmit,
      resetuserData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>
