<template>
  <!-- modal -->
  <b-modal
    :id="'modal-edit-user-'+userEditData.id"
    :ref="'modal-edit-user-'+userEditData.id"
    :title="userEditData.type + ' ' + userEditData.fullName"
    ok-title="Salva"
    cancel-title="Chiudi"
    cancel-variant="outline-secondary"
    size="xl"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <validation-observer ref="editUserForm">
      <form @submit.stop.prevent="validationForm">

        <b-row>
          <b-col
            lg="2"
            md="6"
            sm="12"
            cols="12"
          >
            <b-form-group
              label="Codice"
              label-for="code-input"
            >
              <b-form-input
                id="code-input"
                v-model="userData.code"
                readonly
              />
            </b-form-group>
          </b-col>

          <b-col
            lg="2"
            md="6"
            sm="12"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="type"
              rules="required"
            >
              <b-form-group
                label="Tipo"
                label-for="type-input"
                invalid-feedback="Status is required"
              >
                <v-select
                  v-model="userData.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="typeOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  :disabled="userData.type === 'incaricato'"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  Obbligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            lg="2"
            md="6"
            sm="12"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="status"
              rules="required"
            >
              <b-form-group
                label="Status"
                label-for="status-input"
                invalid-feedback="Status is required"
              >
                <v-select
                  v-model="userData.active"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  Obbligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            lg="2"
            md="6"
            sm="12"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >

              <b-form-group
                label="Nome"
                label-for="name-input"
                invalid-feedback="Obbligatorio"
              >

                <b-form-input
                  id="name-input"
                  v-model="userData.name"
                  :state="getValidationState(validationContext)"
                  required
                />
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            lg="2"
            md="6"
            sm="12"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="last_name"
              rules="required"
            >

              <b-form-group
                label="Cognome"
                label-for="last_name-input"
                invalid-feedback="Obbligatorio"
              >

                <b-form-input
                  id="last_name-input"
                  v-model="userData.last_name"
                  :state="getValidationState(validationContext)"
                  required
                />

              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            lg="2"
            md="6"
            sm="12"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="Prefix"
              rules="required"
            >

              <b-form-group
                label="Email"
                label-for="email-input"
                invalid-feedback="Obbligatorio"
              >

                <b-form-input
                  id="email-input"
                  v-model="userData.email"
                  :state="getValidationState(validationContext)"
                  required
                />

              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            lg="2"
            md="6"
            sm="12"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="Prefix"
              rules="required"
            >

              <b-form-group
                label="Prefisso"
                label-for="prefix-input"
                invalid-feedback="Obbligatorio"
              >

                <b-form-input
                  id="prefix-input"
                  v-model="userData.prefix"
                  :state="getValidationState(validationContext)"
                  required
                />

              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            lg="2"
            md="6"
            sm="12"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="phone_number"
              rules="required"
            >

              <b-form-group
                label="Telefono"
                label-for="phone-input"
                invalid-feedback="Obbligatorio"
              >

                <b-form-input
                  id="phone-input"
                  v-model="userData.phone_number"
                  :state="getValidationState(validationContext)"
                  required
                />

              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            lg="2"
            md="6"
            sm="12"
            cols="12"
          >
            <validation-provider
              #default="validationContext"
              name="fiscal_code"
              rules="required"
            >
              <b-form-group
                label="Codice fiscale"
                label-for="fiscal_code-input"
                invalid-feedback="Obbligatorio"
              >
                <b-form-input
                  id="fiscal_code-input"
                  v-model="userData.fiscal_code"
                  :state="getValidationState(validationContext)"
                  required
                />
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

        <template v-if="userData.type === 'incaricato'">

          <div class="divider my-1">
            <div class="divider-text">
              Documenti & info personali
            </div>
          </div>

          <b-row>

            <b-col
              lg="2"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="birthplace"
                rules="required"
              >
                <b-form-group
                  label="Nato a"
                  label-for="birthplace-input"
                  invalid-feedback="Obbligatorio"
                >
                  <b-form-input
                    id="birthplace-input"
                    v-model="userData.incaricato.birthplace"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="province"
                rules="required|alpha"
              >
                <b-form-group
                  label="Provincia di"
                  label-for="province-input"
                  invalid-feedback="Obbligatorio"
                >
                  <b-form-input
                    id="province-input"
                    v-model="userData.incaricato.province"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="nation_of_birth"
                rules="required"
              >
                <b-form-group
                  label="Stato"
                  label-for="nation_of_birth-input"
                  invalid-feedback="Obbligatorio"
                >
                  <b-form-input
                    id="nation_of_birth-input"
                    v-model="userData.incaricato.nation_of_birth"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="gender"
                rules="required"
              >
                <b-form-group
                  label="Genere"
                  label-for="gender-input"
                  invalid-feedback="Obbligatorio"
                >
                  <v-select
                    v-model="userData.incaricato.gender"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="genderOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    Obbligatorio
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="identity_card"
                rules="required"
              >
                <b-form-group
                  label="Documento"
                  label-for="identity_card-input"
                  invalid-feedback="Obbligatorio"
                >
                  <b-form-input
                    id="identity_card-input"
                    v-model="userData.incaricato.identity_card"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="identity_card_number"
                rules="required"
              >
                <b-form-group
                  label="Numero documento"
                  label-for="identity_card_number-input"
                  invalid-feedback="Obbligatorio"
                >
                  <b-form-input
                    id="identity_card_number-input"
                    v-model="userData.incaricato.identity_card_number"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="public_authority"
                rules="required"
              >
                <b-form-group
                  label="Rilasciato da"
                  label-for="public_authority-input"
                  invalid-feedback="Obbligatorio"
                >
                  <b-form-input
                    id="public_authority-input"
                    v-model="userData.incaricato.public_authority"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="6"
              sm="12"
              cols="12"
            >
              <b-form-group
                label="Rilasciato il"
                label-for="release_date"
                invalid-feedback="Obbligatorio"
              >
                <cleave
                  id="release_date-input"
                  v-model="userData.incaricato.release_date"
                  class="form-control"
                  :raw="false"
                  :options="{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }"
                  placeholder="GG/MM/AAAA"
                />
                <b-form-invalid-feedback :state="errors.release_date">
                  Obbligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              lg="2"
              md="6"
              sm="12"
              cols="12"
            >
              <b-form-group
                label="Scadenza il"
                label-for="expiration_date"
                invalid-feedback="Obbligatorio"
              >
                <cleave
                  id="expiration_date-input"
                  v-model="userData.incaricato.expiration_date"
                  class="form-control"
                  :raw="false"
                  :options="{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }"
                  placeholder="GG/MM/AAAA"
                />
                <b-form-invalid-feedback :state="errors.expiration_date">
                  Obbligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              lg="2"
              md="6"
              sm="12"
              cols="12"
            >
              <b-form-group
                label="Nato il"
                label-for="birthday"
                invalid-feedback="Obbligatorio"
              >
                <cleave
                  id="birthday-input"
                  v-model="userData.incaricato.birthday"
                  class="form-control"
                  :raw="false"
                  :options="{ date: true, delimiter: '/', datePattern: ['d', 'm', 'Y'] }"
                  placeholder="GG/MM/AAAA"
                />
                <b-form-invalid-feedback :state="errors.birthday">
                  Obbligatorio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col
              lg="2"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="spouse"
                rules="required"
              >
                <b-form-group
                  label="Sposata/o"
                  label-for="spouse-input"
                  invalid-feedback="Obbligatorio"
                >
                  <v-select
                    v-model="userData.incaricato.spouse"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="siNoOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    Obbligatorio
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="first_name_spouse"
                rules=""
              >
                <b-form-group
                  label="Nome coniuge"
                  label-for="first_name_spouse-input"
                  invalid-feedback="Obbligatorio"
                >
                  <b-form-input
                    id="first_name_spouse-input"
                    v-model="userData.incaricato.first_name_spouse"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="last_name_spouse"
                rules=""
              >
                <b-form-group
                  label="Cognome coniuge"
                  label-for="last_name_spouse-input"
                  invalid-feedback="Obbligatorio"
                >
                  <b-form-input
                    id="last_name_spouse-input"
                    v-model="userData.incaricato.last_name_spouse"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="inps"
                rules="required"
              >
                <b-form-group
                  label="Inps"
                  label-for="inps-input"
                  invalid-feedback="Obbligatorio"
                >
                  <v-select
                    v-model="userData.incaricato.inps"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="siNoOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    Obbligatorio
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="retired"
                rules="required"
              >
                <b-form-group
                  label="Pensionata/o"
                  label-for="retired-input"
                  invalid-feedback="Obbligatorio"
                >
                  <v-select
                    v-model="userData.incaricato.retired"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="siNoOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    Obbligatorio
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              lg="2"
              md="6"
              sm="12"
              cols="12"
            >
              <validation-provider
                #default="validationContext"
                name="iban"
                rules=""
              >
                <b-form-group
                  label="Iban"
                  label-for="iban-input"
                  invalid-feedback="Obbligatorio"
                >
                  <b-form-input
                    id="iban-input"
                    v-model="userData.incaricato.iban"
                    :state="getValidationState(validationContext)"
                    required
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

        </template>
        <template v-else>
          <b-row>

            <b-col
              lg="2"
              md="6"
              sm="12"
              cols="12"
            >

              <validation-provider
                #default="validationContext"
                name="iban"
                rules=""
              >
                <b-form-group
                  label="Azienda"
                  label-for="company-input"
                >
                  <v-select
                    v-model="userData.company"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="siNoOptions"
                    :reduce="val => val.value"
                    :clearable="false"
                    @input="userData.cliente.business_name = null;
                            userData.cliente.vat = null;"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    Obbligatorio
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <template v-if="userData.company">
              <b-col
                lg="2"
                md="6"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="business_name"
                  rules="required"
                >
                  <b-form-group
                    label="Nome Azienda"
                    label-for="business_name-input"
                    invalid-feedback="Obbligatorio"
                  >
                    <b-form-input
                      id="business_name-input"
                      v-model="userData.cliente.business_name"
                      :state="getValidationState(validationContext)"
                      required
                      :readonly="!userData.company"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="2"
                md="6"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="vat"
                  rules="required|integer"
                >
                  <b-form-group
                    label="P.IVA"
                    label-for="vat-input"
                    invalid-feedback="Obbligatorio e numerico"
                  >
                    <b-form-input
                      id="vat-input"
                      v-model="userData.cliente.vat"
                      :state="getValidationState(validationContext)"
                      required
                      :readonly="!userData.company"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col
                lg="2"
                md="6"
                sm="12"
                cols="12"
              >
                <validation-provider
                  #default="validationContext"
                  name="uniquecode"
                  rules="min:6"
                >
                  <b-form-group
                    label="Codice univoco"
                    label-for="uniquecode-input"
                    invalid-feedback="Minimo 6 caratteri"
                  >
                    <b-form-input
                      id="uniquecode-input"
                      v-model="userData.cliente.uniquecode"
                      :state="getValidationState(validationContext)"
                      required
                      :readonly="!userData.company"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
            </template>

          </b-row>

        </template>

        <b-row class="pt-2">

          <b-col
            lg="2"
            md="6"
            sm="12"
            cols="12"
          >
            <b-link
              class="text-danger"
              @click="swalConfirmChangePassword"
            >
              Reset password
            </b-link>
          </b-col>
        </b-row>

      </form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BFormInput, BModal, VBModal, BRow, BCol, BLink, BFormInvalidFeedback,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import store from '@/store'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required, alphaNum, integer, digits,
} from '@validations'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BModal,
    vSelect,
    BRow,
    BCol,
    BLink,
    BFormInvalidFeedback,
    Cleave,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    userEditData: {
      type: Object,
      required: true,
    },
    typeOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    genderOptions: {
      type: Array,
      required: true,
    },
    siNoOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      nameState: null,
      last_nameState: null,
      emailState: null,
      roleState: null,
      statusState: null,
      passwordState: null,
      submittedNames: [],

      required,
      alphaNum,
      integer,
      digits,
    }
  },
  methods: {
    validationForm() {
      this.$refs.editUserForm.validate().then(success => {
        if (success) {
          this.handleSubmit()
        }
      })
    },
    resetModal() {
      this.nameState = null
      this.last_nameState = null
      this.emailState = null
      this.roleState = null
      this.statusState = null
      this.passwordState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.validationForm()
    },
    handleSubmit() {
      this.onSubmit(this.userEditData.id)

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs[`modal-edit-user-${this.userEditData.id}`].toggle('#toggle-btn')
      })
    },
    swalConfirmChangePassword() {
      this.$swal({
        title: 'Sei sicura/o?',
        text: 'Non potrai annullare questa operazione',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Resetta password',
        cancelButtonText: 'Chiudi',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            store.dispatch('users/resetPassword', {
              email: this.userEditData.email,
            })
              .then(() => {
                this.$emit('refetch-data')
                this.$swal({
                  icon: 'success',
                  title: 'Password resettata!',
                  text: 'Una nuova password è stata inviata all\'utente-loggato',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Operazione annullata',
              text: '',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          return result.value
        })
    },
  },
  setup(props, { emit }) {
    const errors = ref({
      release_date: null,
      expiration_date: null,
      birthday: null,
    })

    const blankUserData = {
      id: props.userEditData.id,
      code: props.userEditData.code,
      name: props.userEditData.personal_data.name,
      last_name: props.userEditData.personal_data.last_name,
      email: props.userEditData.email,
      type: props.userEditData.type,
      company: props.userEditData.company,
      active: props.userEditData.active,
      prefix: props.userEditData.personal_data.prefix,
      phone_number: props.userEditData.personal_data.phone_number,
      fiscal_code: props.userEditData.personal_data.fiscal_code,
      incaricato: props.userEditData.personal_data.incaricato ? {
        identity_card: props.userEditData.personal_data.incaricato.identity_card,
        identity_card_number: props.userEditData.personal_data.incaricato.identity_card_number,
        public_authority: props.userEditData.personal_data.incaricato.public_authority,
        release_date: props.userEditData.personal_data.incaricato.release_date,
        expiration_date: props.userEditData.personal_data.incaricato.expiration_date,
        birthday: props.userEditData.personal_data.incaricato.birthday,
        birthplace: props.userEditData.personal_data.incaricato.birthplace,
        province: props.userEditData.personal_data.incaricato.province,
        nation_of_birth: props.userEditData.personal_data.incaricato.nation_of_birth,
        gender: props.userEditData.personal_data.incaricato.gender,
        spouse: props.userEditData.personal_data.incaricato.spouse,
        first_name_spouse: props.userEditData.personal_data.incaricato.first_name_spouse,
        last_name_spouse: props.userEditData.personal_data.incaricato.last_name_spouse,
        iban: props.userEditData.personal_data.incaricato.iban,
        inps: props.userEditData.personal_data.incaricato.inps,
        retired: props.userEditData.personal_data.incaricato.retired,
      } : [],
      cliente: props.userEditData.personal_data.cliente ? {
        vat: props.userEditData.personal_data.cliente.vat,
        uniquecode: props.userEditData.personal_data.cliente.uniquecode,
        business_name: props.userEditData.personal_data.cliente.business_name,
      } : null,
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    function checkRequired() {
      if (userData.value.type === 'incaricato') {
        errors.value.release_date = !!userData.value.incaricato.release_date
        errors.value.expiration_date = !!userData.value.incaricato.expiration_date
        errors.value.birthday = !!userData.value.incaricato.birthday
        return !!userData.value.incaricato.release_date && !!userData.value.incaricato.expiration_date && !!userData.value.incaricato.birthday
      }
      return true
    }

    const onSubmit = () => {
      console.log(userData.value)
      if (checkRequired()) {
        let sendData = { ...userData.value }
        if (sendData.type === 'incaricato') {
          const { incaricato } = sendData
          delete sendData.incaricato
          sendData = { ...sendData, ...incaricato }
        } else {
          const { cliente } = sendData
          delete sendData.cliente
          sendData = { ...sendData, ...cliente }
        }
        store.dispatch('users/updateIscritto', sendData)
          .then(() => {
            emit('refetch-data')
          })
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      errors,
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>
