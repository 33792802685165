<template>
  <div style="height: inherit">

    <!-- Filters -->
    <!--    <users-list-filters-->
    <!--      :gender-filter.sync="genderFilter"-->
    <!--      :status-filter.sync="statusFilter"-->
    <!--      :gender-options="genders"-->
    <!--      :status-options="status"-->
    <!--      :view-type.sync="viewType"-->
    <!--    />-->

    <users-table />
  </div>
</template>

<script>

// eslint-disable-next-line import/extensions
import Ripple from 'vue-ripple-directive'
import UsersTable from '@/views/iscritti/iscritti/iscritti-list/UsersTable.vue'
import useItemsList from './useUserList'

export default {
  name: 'Users',
  components: {
    UsersTable,
    // ItemsThumbnails,
    // NewItem,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      brandsOptions,
      locationsOptions,
      fetchIscritti,
      refetchIscritto,
      getListIscritti,
      getDraftIscritto,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      periodsOptions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refIscrittiListTable,
    } = useItemsList()

    return {
      brandsOptions,
      locationsOptions,
      fetchIscritti,
      refetchIscritto,
      getListIscritti,
      getDraftIscritto,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      periodsOptions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refIscrittiListTable,
    }
  },
}
</script>

<style lang="scss">
    @import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
    .item-view-radio-group ::v-deep {
        .btn {
            display: flex;
            align-items: center;
        }
    }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
